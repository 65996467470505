<template>
  <div class="layout-container">
    <router-view />
    <!-- 底部导航 -->
    <van-tabbar v-model="active" route active-color="#21A0FF">
      <van-tabbar-item to="/">
        <template #icon>
          <van-image
            width="20"
            height="20"
            :src="require('./image/image_2023-02-27_18-27-31.png')"
          />
        </template>
        {{$t('HOME')}}
      </van-tabbar-item>
      <van-tabbar-item to="/wallet/loansComponent">
        <template #icon>
          <van-image
            width="20"
            height="20"
            :src="require('./image/image_2023-02-27_18-34-46.png')"
          />
        </template>
        {{$t('WALLET')}}
      </van-tabbar-item>
      <van-tabbar-item to="/service">
        <template #icon>
          <van-image
            width="20"
            height="20"
            :src="require('./image/image_2023-02-27_18-35-43.png')"
          />
        </template>
       {{$t('SERVICE')}}
      </van-tabbar-item>
      <van-tabbar-item :dot="isdot" to="/my">
        <template #icon>
          <van-image
            width="20"
            height="20"
            :src="require('./image/image_2023-02-27_18-36-18.png')"
          />
        </template>
        {{$t('MY')}}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { getunreadInformation } from '@/api/my'
// import { getItem } from '@/utils/storage'
export default {
  name: 'LayoutIndex',
  data () {
    return {
      active: 0,
      isdot: false
    }
  },
  methods: {
    async unread () {
      try {
        const { data } = await getunreadInformation()
        if (data.code === 200) {
          if (data.data.loan || data.data.withdrawal) {
            this.isdot = true
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  created () {
    // if (getItem('token')) {
    //   console.log(getItem('token'))
    this.unread()
    // setInterval(() => {
    //   this.unread()
    // }, 1 * 1000)
    // }
  }
}
</script>

<style scoped>
.layout-container >>> .van-tabbar {
  height: 60px;
}
</style>
