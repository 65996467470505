import request from '@/utils/request'

// /user/myInformation我的资料
export const myInformation = data => {
  return request({
    method: 'post',
    url: '/user/myInformation',
    data
  })
}
// /identityInfo/get获取身份信息
export const get = data => {
  return request({
    method: 'post',
    url: '/identityInfo/get',
    data
  })
}
// /identityInfo/save保存身份信息
export const save = data => {
  return request({
    method: 'post',
    url: '/identityInfo/save',
    data
  })
}
// /information/get获取资料信息
export const informationget = data => {
  return request({
    method: 'post',
    url: '/information/get',
    data
  })
}
// /information/relationsList亲属关系列表
export const relationsList = data => {
  return request({
    method: 'post',
    url: '/information/relationsList',
    data
  })
}
// /information/save保存资料信息
export const saveinformation = data => {
  return request({
    method: 'post',
    url: '/information/save',
    data
  })
}
// /bank/get获取银行卡
export const getbank = data => {
  return request({
    method: 'post',
    url: '/bank/get',
    data
  })
}
// /bank/getBankNameList获取银行名称列表
export const getBankNameList = data => {
  return request({
    method: 'post',
    url: '/bank/getBankNameList',
    data
  })
}
// /loan/myLoan我的贷款
export const myLoan = data => {
  return request({
    method: 'post',
    url: '/loan/myLoan',
    data
  })
}
// /loan/details贷款详情
export const details = data => {
  return request({
    method: 'post',
    url: '/loan/details',
    params: data
  })
}
// /bank/addBank添加银行卡
export const addBank = data => {
  return request({
    method: 'post',
    url: '/bank/addBank',
    data
  })
}
// /identityInfo/saveProofIncome保存收入证明
export const saveProofIncome = data => {
  return request({
    method: 'post',
    url: '/identityInfo/saveProofIncome',
    data
  })
}
// /withdrawal/myWithdrawal我的提现
export const getmyWithdrawal = data => {
  return request({
    method: 'post',
    url: '/withdrawal/myWithdrawal',
    params: data
  })
}
// /user/vip购买vip
export const vip = data => {
  return request({
    method: 'post',
    url: '/user/vip',
    params: data
  })
}
// /user/logOut退出登录
export const logOut = data => {
  return request({
    method: 'post',
    url: '/user/logOut',
    params: data
  })
}
// /identityInfo/getProofIncome获取收入证明
export const getProofIncome = data => {
  return request({
    method: 'post',
    url: '/identityInfo/getProofIncome',
    params: data
  })
}
// /withdrawal/withdrawalDetails
export const withdrawalDetails = data => {
  return request({
    method: 'post',
    url: '/withdrawal/withdrawalDetails',
    params: data
  })
}
// /user/myInfo我的信息
export const myInfo = data => {
  return request({
    method: 'post',
    url: '/user/myInfo',
    data
  })
}
// /user/unreadInformation我的未读信息
export const getunreadInformation = data => {
  return request({
    method: 'post',
    url: '/user/unreadInformation',
    data
  })
}
// /user/changePassword
export const changePassword = data => {
  return request({
    method: 'post',
    url: '/user/changePassword',
    params: data
  })
}
// /contact/getContactConfig
export const getContactConfig = data => {
  return request({
    method: 'post',
    url: '/contact/getContactConfig',
    params: data
  })
}
export const getLineUrl = data => {
  return request({
    method: 'post',
    url: '/contact/getLineUrl',
    params: data
  })
}
// /loan/repaymentList
export const repaymentList = data => {
  return request({
    method: 'post',
    url: '/loan/repaymentList',
    params: data
  })
}
// 上传签名
export const autograph = data => {
  return request({
    method: 'post',
    url: '/information/autograph',
    params: data
  })
}
// 获取签名
export const getAutograph = data => {
  return request({
    method: 'post',
    url: '/information/getAutograph',
    params: data
  })
}
// /loan/getLoanAgreementInfo 查看合同
export const getLoanAgreementInfo = data => {
  return request({
    method: 'post',
    url: '/loan/getLoanAgreementInfo',
    params: data
  })
}
export const payment = params => {
  return request({
    method: 'post',
    url: '/payment/pay',
    params: params
  })
}
